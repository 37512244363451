import jwt_decode from "jwt-decode";
import moment from 'moment';
import { AlertMessages } from "../language/Texts";
import translation from '../language/translation.json';
import { IDownloadOptions } from "../models/IDownloadOptions";
import { IPdfDimensions } from "../models/IPdfDimensions";
import { ITokenDecoded } from '../models/ITokenDecoded';
import { Messages } from "../models/Messages";
import XLSX from 'xlsx';
import { IdAndLink } from "../models/IdAndLink";

export class Utils {
    static tokenDecoder(token: string): string {
        const decoded: ITokenDecoded = jwt_decode(token);
        return decoded.email
    }

    static getUserInfos(token: string): { roles: string[], email: string } {
        const decoded: ITokenDecoded = jwt_decode(token);
        return { roles: decoded?.realm_access.roles, email: decoded.email }
    }

    static setSidebarWidth() {
        let openWidth = ''
        let closedWidth = ''
        const windowWidth = window.innerWidth

        if (windowWidth < 350) {
            openWidth = "100px"
            closedWidth = "35px"
        } else if (windowWidth < 500) {
            openWidth = "150px"
            closedWidth = "40px"
        } else if (windowWidth < 750) {
            openWidth = "200px"
            closedWidth = "45px"
        } else {
            openWidth = "250px"
            closedWidth = "60px"
        }
        return { openWidth, closedWidth }
    }

    static getMessageText(message: string) {
        switch (message) {
            case Messages.SUCCESSFULLY_UPDATED:
                return AlertMessages.successfullyUpdated
            case Messages.SUCCESSFULLY_DELETED:
                return AlertMessages.successfullyDeleted
            case Messages.SUCCESSFULLY_CREATED:
                return AlertMessages.successfullyCreated
            case Messages.SUCCESSFULLY_GET_LIST:
                return AlertMessages.successfullyGetList
            case Messages.FALSE_ID:
                return AlertMessages.falseId
            case Messages.UPDATE_ERROR:
                return AlertMessages.updateError
            case Messages.DELETE_ERROR:
                return AlertMessages.deleteError
            case Messages.CREATE_ERROR:
                return AlertMessages.createError
            case Messages.GET_LIST_ERROR:
                return AlertMessages.getListError
            case Messages.DECYRPT_ERROR:
                return AlertMessages.decyrptError
            case Messages.EXTRACT_ERROR:
                return AlertMessages.extractError
            case Messages.DB_CREATE_ERROR:
                return AlertMessages.dbCreateError
            case Messages.UNIQUE_VIOLATION:
                return AlertMessages.alreadyExistError
            default:
                return message
        }
    }

    static arrangeTime(time: string) {
        return moment(time).format('DD.MM.YYYY - HH:mm')
    }

    static isRatioCorrect(pdfDimensions: IPdfDimensions) {
        const ratio = pdfDimensions.pdfWidth / pdfDimensions.pdfHeight
        if (!pdfDimensions.pdfWidth || !pdfDimensions.pdfHeight || pdfDimensions.pdfWidth == 0 || pdfDimensions.pdfHeight == 0) {
            return false
        }
        if (ratio < 0.75 || ratio > 1.3) {
            return true
        } else {
            return false
        }
    }

    static isDimensionsCorrect(pdfDimensions: IPdfDimensions) {
        const isSizeCorrect = pdfDimensions.pdfWidth < 1001 && pdfDimensions.pdfHeight < 1001
        if (isSizeCorrect) {
            return true
        } else {
            return false
        }
    }

    static handleDownloadOptions(option: string, setDownloadOptions: (downloadOptions: IDownloadOptions) => void, downloadOptions: IDownloadOptions) {
        switch (option) {
            case "excel":
                setDownloadOptions({ ...downloadOptions, excel: !downloadOptions.excel })
                break;
            case "png":
                setDownloadOptions({ ...downloadOptions, png: !downloadOptions.png })
                break;
            case "pdf":
                setDownloadOptions({ ...downloadOptions, pdf: !downloadOptions.pdf })
                break;
        }
    }

    static setDataTypeAndDownloadFile = (
        pdfDimensions: { pdfWidth: number; pdfHeight: number; },
        dimensionWarning: (message: string) => void,
        downloadOptions: IDownloadOptions,
        downloadFile: (dataType: string) => Promise<void>,
        isMultiple = false,
        isRegenerate?: boolean,

    ) => {
        if (!this.isRatioCorrect(pdfDimensions)) {
            dimensionWarning('PDF Format: Breite/Höhe muss weniger als 0.75 oder mehr als 1.3 sein')
            return
        }
        if (!this.isDimensionsCorrect(pdfDimensions)) {
            dimensionWarning('PDF Format: Breite und Höhe muss weniger als 1000mm sein ')
            return
        }
        // only excel
        if (!isMultiple && downloadOptions.excel && !downloadOptions.pdf && !downloadOptions.png) {
            downloadFile(".xlsx")
            // only pdf
        } else if (!downloadOptions.excel && downloadOptions.pdf && !downloadOptions.png) {
            downloadFile(".pdf")
            // only png if regenerate
        } else if (!isMultiple && isRegenerate && !downloadOptions.excel && !downloadOptions.pdf && downloadOptions.png) {
            downloadFile(".png")
        } else {
            downloadFile(".zip")
        }
    }

    static excelFileToArray = (sheet: XLSX.WorkSheet): string[] => {
        const idAndLinks: IdAndLink[] = XLSX.utils.sheet_to_json(sheet);

        const linksArray = idAndLinks
            .filter((item) => item["idLink"])
            .map((item) => item["idLink"]);

        return linksArray;
    };

    static isValidUrl(url: string) {
        try {
            new URL(url);
            return true;
        } catch (_) {
            return false;
        }
    }

    static getBrowserLanguage(): string {
        return navigator.language.substring(0, 2)
    }

    static isLanguageSupported(lang: string): boolean {
        return translation.translation.language[lang]
    }

    static isDevelopment(): boolean {
        return (window.location.hostname === 'ilg-test-murat.conexo.support' || window.location.hostname === 'localhost')
    }
}