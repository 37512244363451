import { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import AppHeader from '../../components/header/AppHeader'
import Loader from '../../components/loader/Loader'
import { GeneralTexts } from '../../language/Texts'
import { FileDetail } from '../../models/IHistoryFileList'
import { IIdLinkCreate } from '../../models/IIdCreate'
import { IManufacturerItem } from '../../models/IManufacturerList'
import { IManufacturerOption } from '../../models/IManufacturerOption'
import { IPdfDimensions } from '../../models/IPdfDimensions'
import { HttpRequests } from '../../utils/HttpRequests'
import { setFailOptions, setSuccessOptions } from '../../utils/SetSuccessAndFailOptions'
import { Utils } from '../../utils/Utils'
import History from './History'
import './IdLinkCreate.css'
import IdLinkInputPart from './IdLinkInputPart'
import IdLinkTitlePart from './IdLinkTitlePart'
import { IAlertOptions } from '../../models/IAlertOptions'

interface IdLinkCreateProps {
    getManufacturerList: () => void,
    historyFileList: FileDetail[],
    filteredManufacturerList: IManufacturerItem[] | undefined
}

function IdLinkCreate({ getManufacturerList, filteredManufacturerList, historyFileList }: IdLinkCreateProps) {
    const httpRequests = new HttpRequests()
    const [showLoader, setShowLoader] = useState(false)
    const [alertOpt, setAlertOpt] = useState<IAlertOptions>({ isOpen: false, message: '', variant: '' })
    const [manufacturer, setManufacturer] = useState<IManufacturerItem | null>(null)
    const [quantity, setQuantity] = useState<string>('')
    const [downloadOptions, setDownloadOptions] = useState({ excel: false, png: false, pdf: true })
    const [pdfDimensions, setPdfDimensions] = useState<IPdfDimensions>({ pdfWidth: 35, pdfHeight: 14 })
    const [manufacturerOption, setManufacturerOption] = useState<IManufacturerOption | null>(null)

    const isButtonDisabled = () => {
        let hasQuantity = quantity && parseInt(quantity) > 0
        return !(hasQuantity && manufacturerOption && !showLoader)
    }

    const dimensionWarning = (message: string) => {
        setAlertOpt({ isOpen: true, message: message, variant: 'warning' })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
        }, 5000)
    }

    const downloadFile = async (fileType: string) => {
        setShowLoader(true)
        let requestData: IIdLinkCreate = { manufacturer: manufacturer!, quantity: parseInt(quantity), pdfDimensions: { pdfWidth: pdfDimensions.pdfWidth / 10, pdfHeight: pdfDimensions.pdfHeight / 10 }, downloadOptions: downloadOptions }
        try {
            const result = await httpRequests.createIdLinksAndGetFile(requestData)
            const successOptProps = { serverResponse: result, fileType, setAlertOpt, alertOpt, downloadFile }
            setSuccessOptions(successOptProps)
        }
        catch (error) {
            const failOptProps = { error, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
        reset()
    }

    const reset = () => {
        getManufacturerList()
        setShowLoader(false)
        setManufacturerOption(null)
        setQuantity('')
        setDownloadOptions({ excel: false, png: false, pdf: true })
        setPdfDimensions({ pdfWidth: 35, pdfHeight: 14 })
    }

    return (
        <div className='id_link_wrapper'>
            <AppHeader />

            <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
                <Loader />
            </div>

            <Alert className="id_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <IdLinkTitlePart />

            <Form>
                <IdLinkInputPart
                    filteredManufacturerList={filteredManufacturerList}
                    setManufacturer={setManufacturer}
                    setQuantity={setQuantity}
                    manufacturerOption={manufacturerOption}
                    setManufacturerOption={setManufacturerOption}
                    downloadOptions={downloadOptions}
                    setDownloadOptions={setDownloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    quantity={quantity!}
                    disabled={showLoader}
                />
                <div className="id_button_group">
                    <Button
                        data-testid="create"
                        type="reset"
                        onClick={() =>
                            Utils.setDataTypeAndDownloadFile(
                                pdfDimensions,
                                dimensionWarning,
                                downloadOptions,
                                downloadFile)}
                        className='general_button'
                        disabled={isButtonDisabled()}
                    > {GeneralTexts.generate}
                    </Button>
                </div>
            </Form>
            {historyFileList?.length > 0 ? <History historyFileList={historyFileList} /> : null}
        </div>
    )
}

export default IdLinkCreate
