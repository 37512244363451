import { faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { Form } from "react-bootstrap"
import CheckBox from "../../components/CheckBox"
import { GeneralTexts, IdLinkCreatePage, QrRecreatePage } from "../../language/Texts"
import { IDownloadOptions } from "../../models/IDownloadOptions"
import { IPdfDimensions } from "../../models/IPdfDimensions"
import { Utils } from "../../utils/Utils"

interface InputPartProps {
    inputValue: string
    setInputValue: (value: string) => void,
    downloadOptions: IDownloadOptions,
    setDownloadOptions: (downloadOptions: IDownloadOptions) => void,
    pdfDimensions: IPdfDimensions,
    setPdfDimensions: ({ pdfWidth, pdfHeight }) => void,
    disabled: boolean,
    isExcelFile: boolean,
    handleFileUpload: (event) => void,
    imageSrc: string,
    selectedFile: any,
    resetExcelFile: () => void
}

function QrRegenerationInputPart({
    setInputValue, inputValue,
    setDownloadOptions, downloadOptions,
    setPdfDimensions, pdfDimensions,
    disabled,
    isExcelFile,
    handleFileUpload,
    imageSrc,
    selectedFile,
    resetExcelFile
}: InputPartProps) {

    const handleDownloadOptions = (option: string) => {
        Utils.handleDownloadOptions(option, setDownloadOptions, downloadOptions)
    }

    useEffect(() => {
    }, [imageSrc])

    return (
        <div className="id_input_part">
            <div className="id_label regenerate_input_1">
                <label className='id_label'>{QrRecreatePage.enterIdLink} </label>
                <input
                    value={inputValue}
                    placeholder={IdLinkCreatePage.idLink}
                    className='id_number_input  file_input'
                    type="url"
                    required
                    onChange={(e) => setInputValue(e.target.value)}
                    disabled={disabled || isExcelFile}
                />

                <Form.Group controlId="formFileSm" className="mb-3">
                    <Form.Label className='id_label file_label'>{GeneralTexts.or}</Form.Label>
                    <Form.Label className='id_label file_label'>{QrRecreatePage.importExcelFile}</Form.Label>
                    <Form.Control
                        data-testid='file-input'
                        className='id_number_input file_input'
                        disabled={disabled || inputValue !== ''}
                        accept=".xlsx"
                        type="file"
                        value={selectedFile}
                        onChange={(event) => handleFileUpload(event)}
                    />
                    {selectedFile &&
                        <FontAwesomeIcon
                            onClick={() => resetExcelFile()}
                            className="remove_file_icon" icon={faTrashCan} />
                    }
                </Form.Group>
            </div>

            <div className="regenerate_input_2">
                {imageSrc &&
                    <div className="preview_wrapper">
                        <p className="preview_text">Vorschau</p>
                        <img className="preview_qr" src={imageSrc} alt="preview" />
                    </div>}
                <div className="download_options">
                    <p className="options_title">{GeneralTexts.downloadOptions}:</p>
                    <CheckBox
                        handleDownloadOptions={handleDownloadOptions}
                        downloadOptions={downloadOptions}
                        disabled={disabled}
                        enableExcelDownload={false}
                    />
                    <span>{GeneralTexts.pdfFormat}: </span>
                    <input
                        data-testid="pdf_width"
                        className='pdf_size'
                        type={"number"}
                        inputMode="numeric"
                        value={pdfDimensions.pdfWidth}
                        onChange={(e) => setPdfDimensions({ ...pdfDimensions, pdfWidth: e.target.value })}
                        disabled={disabled}
                    />
                    <span> mm x </span>
                    <input
                        data-testid="pdf_height"
                        className='pdf_size'
                        type={"number"}
                        inputMode="numeric"
                        value={pdfDimensions.pdfHeight}
                        onChange={(e) => setPdfDimensions({ ...pdfDimensions, pdfHeight: e.target.value })}
                        disabled={disabled}
                    />
                    <span> mm</span>
                </div>
            </div>

        </div>
    )
}

export default QrRegenerationInputPart
