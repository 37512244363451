import { AiOutlineQrcode } from 'react-icons/ai'
import logo from '../../assets/conexo.png'
import { RetrofitCreatePage } from '../../language/Texts'

function RetrofitQrTitlePart() {
    return (
        <div className="title-image">
            <h4 className="box_title">
                {RetrofitCreatePage.generateRetrofitQrCode}
            </h4>
            <div className="id_image_text">
                <div className="icon_logo">
                    <AiOutlineQrcode className='id_icon' />
                    <img className="id_logo" src={logo} alt="conexo-logo" />
                    <p className='id_icon_text'>  {RetrofitCreatePage.retrofitQr}</p>
                </div>
                <div className="id_text_part">
                    <h4 className="box_text_title">
                        {RetrofitCreatePage.retrofitQr}
                    </h4>
                    <p className="id_text">
                        {RetrofitCreatePage.retrofitTitle1}
                    </p>
                    <p className="id_text">
                        {RetrofitCreatePage.retrofitTitle2}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RetrofitQrTitlePart
