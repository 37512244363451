import CheckBox from "../../components/CheckBox"
import { GeneralTexts, RetrofitCreatePage } from "../../language/Texts"
import { IDownloadOptions } from "../../models/IDownloadOptions"
import { IPdfDimensions } from "../../models/IPdfDimensions"
import { Utils } from "../../utils/Utils"

interface InputPartProps {
    inputValue: string
    setInputValue: (value: string) => void,
    downloadOptions: IDownloadOptions,
    setDownloadOptions: (downloadOptions: IDownloadOptions) => void,
    pdfDimensions: IPdfDimensions,
    setPdfDimensions: ({ pdfWidth, pdfHeight }) => void,
    disabled: boolean
}

function RetrofitInputPart({ setInputValue, inputValue, downloadOptions, setDownloadOptions, pdfDimensions, setPdfDimensions, disabled }: InputPartProps) {

    const setIdQuantity = (quantity: string) => {
        setInputValue(quantity)
    }

    const handleDownloadOptions = (option: string) => {
        Utils.handleDownloadOptions(option, setDownloadOptions, downloadOptions)
    }

    return (
        <div className="id_input_part">
            <div className="id_label">
                <label className='id_label'>{RetrofitCreatePage.quantityOftheLinks}</label>
                <input
                    value={inputValue}
                    data-testid="quantity"
                    placeholder={GeneralTexts.quantity}
                    className='id_number_input'
                    type={"number"}
                    inputMode="numeric"
                    onChange={(e) => setIdQuantity(e.target.value)}
                    disabled={disabled}
                />
            </div>
            <div className="download_options">
                <p className="options_title">{GeneralTexts.downloadOptions}:</p>
                <CheckBox
                    handleDownloadOptions={handleDownloadOptions}
                    downloadOptions={downloadOptions}
                    disabled={disabled}
                />
                <span>{GeneralTexts.pdfFormat}: </span>
                <input
                    data-testid="pdf_width"
                    className='pdf_size'
                    type={"number"}
                    inputMode="numeric"
                    value={pdfDimensions.pdfWidth}
                    onChange={(e) => setPdfDimensions({ ...pdfDimensions, pdfWidth: e.target.value })}
                    disabled={disabled}
                />
                <span> mm x </span>
                <input
                    data-testid="pdf_height"
                    className='pdf_size'
                    type={"number"}
                    inputMode="numeric"
                    value={pdfDimensions.pdfHeight}
                    onChange={(e) => setPdfDimensions({ ...pdfDimensions, pdfHeight: e.target.value })}
                    disabled={disabled}
                />
                <span> mm</span>
            </div>
        </div>
    )
}

export default RetrofitInputPart
