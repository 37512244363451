import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import CheckBox from '../../components/CheckBox';
import PaginationComponent from '../../components/pagination/PaginationComponent';
import { GeneralTexts } from '../../language/Texts';
import { ContentTypes } from '../../models/ContentTypes';
import { FileDetail } from '../../models/IHistoryFileList';
import { downloadFileOnBrowser } from '../../utils/DownloadFile';
import { useGlobalContext } from '../../utils/GlobalContext';
import { HttpRequests } from '../../utils/HttpRequests';
import { setFailOptions } from '../../utils/SetSuccessAndFailOptions';
import { Utils } from '../../utils/Utils';
import FilteredTable from './FilteredTable';
import HistoryTitlePart from './HistoryTitlePart';
import { dateTitelPart, idTitelPart } from './HistoryUtils';

interface HistoryProps {
    historyFileList: FileDetail[]
}

function History({ historyFileList }: HistoryProps) {
    const [searchId, setSearchId] = useState<string>('')
    const [filteredHistoryElements, setFilteredHistoryElements] = useState<FileDetail[]>()
    const [showLoader, setShowLoader] = useState(false)
    const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [downloadOptions, setDownloadOptions] = useState({ excel: false, png: false, pdf: true })
    const httpRequests = new HttpRequests()
    const [sortOptions, setSortOptions] = useState({
        idIcon: faSort,
        dateIcon: faSort,
        isSortId: false,
        isSortDate: false,
        reversed: false,
        type: ""
    });

    useEffect(() => {
        if (searchId || historyFilterRef.current) {
            filterId()
        }
    }, [searchId])

    useEffect(() => {
        if (historyFileList) {
            createHistoryElementArray()
            adjustSortOptions({
                idIcon: sortOptRef.current.idIcon,
                dateIcon: sortOptRef.current.dateIcon,
                isSortId: sortOptRef.current.isSortId,
                isSortDate: sortOptRef.current.isSortDate,
                reversed: sortOptRef.current.reversed,
                type: sortOptRef.current.type
            })
            filterId()
        }
    }, [historyFileList])

    let contextVariables
    const { totalHistoryPages,
        setTotalHistoryPages,
        totalManufacturerPages,
        setTotalManufacturerPages,
        setManufacturerPage,
        manufacturerPage,
        historyPage,
        setHistoryPage } = contextVariables = useGlobalContext()

    const historyArrayRef = useRef(true)
    const historyFilterRef = useRef(false)
    const sortOptRef = useRef({ idIcon: faSort, dateIcon: faSort, isSortId: false, isSortDate: false, reversed: false, type: 'firstID' })

    const sortHistoryList = (historyElements: FileDetail[], reversed: boolean, type: string) => {
        if (!reversed) {
            return historyElements.sort((a, b) => (a[type] > b[type]) ? 1 : ((b[type] > a[type]) ? -1 : 0))
        } else {
            return historyElements.sort((b, a) => (a[type] > b[type]) ? 1 : ((b[type] > a[type]) ? -1 : 0))
        }
    }

    const createHistoryElementArray = () => {
        setTotalHistoryPages(Math.ceil(historyFileList.length / 10))
        setFilteredHistoryElements(sortHistoryList(historyFileList, true, "birthDate"))
        historyArrayRef.current = false
    }

    const adjustSortOptions = ({ idIcon, dateIcon, isSortId, isSortDate, reversed, type }) => {
        setSortOptions((prev) => ({
            ...prev,
            idIcon,
            dateIcon,
            isSortId,
            isSortDate,
            reversed,
            type,
        }))
        sortOptRef.current = { idIcon, dateIcon, isSortId, isSortDate, reversed, type }
        if (filteredHistoryElements) {
            setFilteredHistoryElements(sortHistoryList(filteredHistoryElements!, reversed, type))
        }
    }

    const sortHistoryId = () => {
        if (sortOptions.isSortId) {
            adjustSortOptions({
                idIcon: faSortUp,
                dateIcon: faSort,
                isSortId: false,
                isSortDate: false,
                reversed: false,
                type: "firstID"
            })
        } else {
            adjustSortOptions({
                idIcon: faSortDown,
                dateIcon: faSort,
                isSortId: true,
                isSortDate: false,
                reversed: true,
                type: "firstID"
            })
        }
    }

    const sortHistoryDate = () => {
        if (sortOptions.isSortDate) {
            adjustSortOptions({
                idIcon: faSort,
                dateIcon: faSortUp,
                isSortId: false,
                isSortDate: false,
                reversed: false,
                type: "birthDate"
            })
        } else {
            adjustSortOptions({
                idIcon: faSort,
                dateIcon: faSortDown,
                isSortId: false,
                isSortDate: true,
                reversed: true,
                type: "birthDate"
            })
        }
    }

    const filterId = () => {
        const filteredHistoryElements = historyFileList?.filter(function (element) {
            return (
                element.firstID.toUpperCase().includes(searchId.toUpperCase()) || element.lastID.toUpperCase().includes(searchId.toUpperCase())
            )
        });
        setTotalHistoryPages(Math.ceil(filteredHistoryElements ? filteredHistoryElements.length / 10 : 0))
        setHistoryPage(0)
        setFilteredHistoryElements(filteredHistoryElements)
        historyFilterRef.current = true
    }

    const handleDownloadOptions = (option: string) => {
        Utils.handleDownloadOptions(option, setDownloadOptions, downloadOptions)
    }

    const setDataTypeAndDownloadFile = (manufacturerId: string, fileDetail: FileDetail) => {
        const downloadName = fileDetail.firstID + "-" + fileDetail.lastID
        // only excel
        if (downloadOptions.excel && !downloadOptions.pdf && !downloadOptions.png) {
            downloadFile(manufacturerId, downloadName, ".xlsx")
            // only pdf
        } else if (!downloadOptions.excel && downloadOptions.pdf && !downloadOptions.png) {
            downloadFile(manufacturerId, downloadName, ".pdf")
        } else {
            downloadFile(manufacturerId, downloadName, ".zip")
        }
    }

    const downloadFile = async (manufacturerId: string, downloadName: string, fileType: string) => {
        setShowLoader(true)
        let requestData = { manufacturerId, fileName: downloadName, downloadOptions }
        try {
            const result = await httpRequests.getFile(requestData)
            setSuccessOptions(result, fileType)
        }
        catch (error) {
            const failOptProps = { error, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
        setShowLoader(false)
        setDownloadOptions({ excel: false, png: false, pdf: true })
    }

    const setSuccessOptions = async (serverResponse: AxiosResponse<File, File>, fileType: string) => {
        if (serverResponse.data.type === ContentTypes.ZIP || ContentTypes.PDF || serverResponse.data.type === ContentTypes.EXCEL) {
            downloadFileOnBrowser(serverResponse, fileType)
            setAlertOpt({ isOpen: true, message: GeneralTexts.successfullyDowndloaded, variant: "success" })
            setTimeout(() => {
                setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
            }, 3000)
        } else {
            const failOptProps = { error: GeneralTexts.sthWrong, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
    }

    return (
        <>
            {historyFileList &&
                <div className='history_wrapper' data-testid="history">
                    < HistoryTitlePart alertOpt={alertOpt} showLoader={showLoader} />
                    <div className="history_table">
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th className='head_item'>{idTitelPart(sortOptions, sortHistoryId, searchId, setSearchId)}</th>
                                    <th className='head_item'>{dateTitelPart(sortOptions, sortHistoryDate)}</th>
                                    <th className='head_item'>Anzahl</th>
                                    <th className='head_item'>
                                        {GeneralTexts.downloadOptions}
                                        <div className="history_checkbox">
                                            <CheckBox
                                                handleDownloadOptions={handleDownloadOptions}
                                                downloadOptions={downloadOptions}
                                            />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <FilteredTable
                                filteredHistoryElements={filteredHistoryElements}
                                setDataTypeAndDownloadFile={setDataTypeAndDownloadFile}
                            />
                        </Table>
                    </div>
                    {totalHistoryPages > 1 ?
                        <PaginationComponent
                            contextVariables={contextVariables}
                            isHistory={true} />
                        : null}
                </div >}
        </>
    )
}

export default History
