import { AxiosResponse } from 'axios'
import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import AppHeader from '../../components/header/AppHeader'
import Loader from '../../components/loader/Loader'
import { GeneralTexts } from '../../language/Texts'
import { ContentTypes } from '../../models/ContentTypes'
import { IDownloadOptions } from '../../models/IDownloadOptions'
import { IQrRegenerate } from '../../models/IQrRegenerate'
import { downloadFileOnBrowser } from '../../utils/DownloadFile'
import { HttpRequests } from '../../utils/HttpRequests'
import { setFailOptions } from '../../utils/SetSuccessAndFailOptions'
import { Utils } from '../../utils/Utils'
import './QrRegeneration.css'
import QrRegenerationInputPart from './QrRegenerationInputPart'
import QrRegenerationTitle from './QrRegenerationTitle'
import XLSX from 'xlsx';

function QrRegeneration() {
    const [showLoader, setShowLoader] = useState(false)
    const [inputValue, setInputValue] = useState<string>('')
    const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [downloadOptions, setDownloadOptions] = useState<IDownloadOptions>({ excel: false, png: false, pdf: true })
    const [pdfDimensions, setPdfDimensions] = useState({ pdfWidth: 35, pdfHeight: 14 })
    const [idLinks, setIdLinks] = useState<string[] | undefined>();
    const [isExcelFile, setIsExcelFile] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [selectedFile, setSelectedFile] = useState<any>('');

    // Process ref is used to prevent multiple requests
    const processRef = useRef(false)

    const isButtonDisabled = () => {
        const isChoosen = downloadOptions.excel || downloadOptions.png || downloadOptions.pdf
        return (inputValue !== '' || (isExcelFile && idLinks && idLinks.length > 1)) && isChoosen ? false : true
    }

    const dimensionWarning = (message: string) => {
        setAlertOpt({ isOpen: true, message: message, variant: 'warning' })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
        }, 5000)
    }

    const downloadFile = async (fileType: string) => {
        setShowLoader(true)
        let requestData: IQrRegenerate = { idLink: [inputValue], pdfDimensions: { pdfWidth: pdfDimensions.pdfWidth / 10, pdfHeight: pdfDimensions.pdfHeight / 10 }, downloadOptions: downloadOptions }
        idLinks && (requestData["idLink"] = idLinks)
        try {
            const result = await new HttpRequests().qrRegenerate(requestData)
            setSuccessOptions(result, fileType)
        }
        catch (error) {
            const failOptProps = { error, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
        resetExcelFile()
        setShowLoader(false)
        setInputValue('')
        setDownloadOptions({ excel: false, png: false, pdf: true })
        setPdfDimensions({ pdfWidth: 35, pdfHeight: 14 })
    }

    const setSuccessOptions = async (serverResponse: AxiosResponse<File, File>, fileType: string) => {
        if (serverResponse.data.type === ContentTypes.ZIP || serverResponse.data.type === ContentTypes.PDF || serverResponse.data.type === ContentTypes.EXCEL) {
            downloadFileOnBrowser(serverResponse, fileType)
            setAlertOpt({ isOpen: true, message: GeneralTexts.successfullyDowndloaded, variant: "success" })
            setTimeout(() => {
                setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
            }, 3000)
        }
        else if (serverResponse.data.type === "application/json") {
            const { message } = JSON.parse(await serverResponse.data.text())
            const failOptProps = { error: message, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        } else {
            const failOptProps = { error: GeneralTexts.sthWrong, option: 'zipFile', setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const isMultiple = idLinks && idLinks?.length > 1 ? true : false
        Utils.setDataTypeAndDownloadFile(
            pdfDimensions,
            dimensionWarning,
            downloadOptions,
            downloadFile,
            isMultiple,
            true)
    }

    const handleFileUpload = (event) => {
        setSelectedFile(event.target.value);
        const file = event.target.files[0];

        if (file) {
            setIsExcelFile(true);
            const reader = new FileReader();

            reader.onload = function (e) {
                const data = e.target!.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const idLinksArray = Utils.excelFileToArray(sheet);
                setIdLinks(idLinksArray);
            };

            reader.readAsBinaryString(file);
        }
    };

    const resetExcelFile = () => {
        setSelectedFile('')
        setIsExcelFile(false)
        setImageSrc('')
        setIdLinks(undefined)
    };

    const debouncedGetOverview = useCallback(
        debounce(async () => {
            setImageSrc('')
            let requestData: IQrRegenerate = { idLink: [inputValue], pdfDimensions: { pdfWidth: pdfDimensions.pdfWidth / 10, pdfHeight: pdfDimensions.pdfHeight / 10 }, downloadOptions: { excel: false, pdf: false, png: true } }
            idLinks && (requestData["idLink"] = [idLinks[0]])

            if (!Utils.isDimensionsCorrect(pdfDimensions) || !Utils.isRatioCorrect(pdfDimensions)) {
                setImageSrc('')
            }
            if (!processRef.current && Utils.isDimensionsCorrect(pdfDimensions) && Utils.isRatioCorrect(pdfDimensions) && (idLinks ? idLinks.length >= 1 : Utils.isValidUrl(inputValue)) && (Utils.isValidUrl(inputValue) || Utils.isValidUrl(idLinks![0]))) {
                processRef.current = true
                try {
                    const result = await new HttpRequests().qrRegenerate(requestData)
                    const url = URL.createObjectURL(result.data);
                    result.data.type === "image/png" && setImageSrc(url);
                }
                catch (error) {
                    console.log('error', error);
                }
                processRef.current = false
            }
        }, 300), // Adjust the debounce delay (300ms in this example)
        [pdfDimensions, inputValue, idLinks]
    );

    useEffect(() => {
        debouncedGetOverview();
        return () => {
            debouncedGetOverview.cancel();
        };
    }, [debouncedGetOverview]);

    return (
        <div className='regenerate_wrapper'>
            <AppHeader />

            <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
                <Loader />
            </div>

            <Alert className="id_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <QrRegenerationTitle />

            <Form onSubmit={handleSubmit} >
                <QrRegenerationInputPart
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    downloadOptions={downloadOptions}
                    setDownloadOptions={setDownloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    disabled={showLoader}
                    isExcelFile={isExcelFile}
                    handleFileUpload={handleFileUpload}
                    imageSrc={imageSrc}
                    selectedFile={selectedFile}
                    resetExcelFile={resetExcelFile}
                />

                <div className="id_button_group">
                    <Button
                        data-testid="create"
                        type="submit"
                        className='general_button'
                        disabled={isButtonDisabled()}
                    > {GeneralTexts.generate}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default QrRegeneration
