import { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import AppHeader from '../../components/header/AppHeader'
import Loader from '../../components/loader/Loader'
import { GeneralTexts } from '../../language/Texts'
import { IDownloadOptions } from '../../models/IDownloadOptions'
import { IIdLinkCreate } from '../../models/IIdCreate'
import { IManufacturerItem } from '../../models/IManufacturerList'
import { RetrofitManufacturer } from '../../models/RetrofitManufacturer'
import { HttpRequests } from '../../utils/HttpRequests'
import { setFailOptions, setSuccessOptions } from '../../utils/SetSuccessAndFailOptions'
import { Utils } from '../../utils/Utils'
import RetrofitInputPart from './RetrofitInputPart'
import RetrofitQrTitlePart from './RetrofitQrTitlePart'
interface SuccessVieweProps {
    manufacturerList: IManufacturerItem[] | undefined,
    getManufacturerList: () => void,
}

const CreateView = ({ manufacturerList, getManufacturerList, }: SuccessVieweProps) => {
    const [showLoader, setShowLoader] = useState(false)
    const [inputValue, setInputValue] = useState<string>('')
    const [alertOpt, setAlertOpt] = useState({ isOpen: false, message: '', variant: '' })
    const [downloadOptions, setDownloadOptions] = useState<IDownloadOptions>({ excel: false, png: false, pdf: true })
    const [pdfDimensions, setPdfDimensions] = useState({ pdfWidth: 35, pdfHeight: 14 })

    const isButtonDisabled = () => {
        let hasQuantity = inputValue && parseInt(inputValue) > 0
        return !(hasQuantity && !showLoader)
    }

    // The difference from idLink create is retrofit manufacturer. 
    // Hier only ids from retrofit manufacturer are downloaded.
    const retrofitManufacturer = manufacturerList?.filter((item) => {
        return item.trusted_manufacturer_id === RetrofitManufacturer.id
    })[0]

    const dimensionWarning = (message: string) => {
        setAlertOpt({ isOpen: true, message: message, variant: 'warning' })
        setTimeout(() => {
            setAlertOpt({ isOpen: false, message: '', variant: alertOpt.variant })
        }, 5000)
    }

    const downloadFile = async (fileType: string) => {
        setShowLoader(true)
        let requestData: IIdLinkCreate = { manufacturer: retrofitManufacturer!, quantity: parseInt(inputValue)!, pdfDimensions: { pdfWidth: pdfDimensions.pdfWidth / 10, pdfHeight: pdfDimensions.pdfHeight / 10 }, downloadOptions: downloadOptions }
        try {
            const result = await new HttpRequests().createIdLinksAndGetFile(requestData)
            const successOptProps = { serverResponse: result, fileType, setAlertOpt, alertOpt, downloadFile }
            setSuccessOptions(successOptProps)
        }
        catch (error) {
            const failOptProps = { error, option: fileType, setAlertOpt, alertOpt }
            setFailOptions(failOptProps)
        }
        reset()
    }

    const reset = () => {
        getManufacturerList()
        setShowLoader(false)
        setInputValue('')
        setDownloadOptions({ excel: false, png: false, pdf: true })
        setPdfDimensions({ pdfWidth: 35, pdfHeight: 14 })
    }

    return (
        <div className='retrofit_wrapper'>
            <AppHeader />

            <div style={{ display: showLoader ? "block" : "none", textAlign: "center" }}>
                <Loader />
            </div>

            <Alert className="id_alert" variant={alertOpt.variant} show={alertOpt.isOpen} >
                {alertOpt.message}
            </Alert>

            <RetrofitQrTitlePart />
            <Form>
                <RetrofitInputPart
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    downloadOptions={downloadOptions}
                    setDownloadOptions={setDownloadOptions}
                    pdfDimensions={pdfDimensions}
                    setPdfDimensions={setPdfDimensions}
                    disabled={showLoader} />

                <div className="id_button_group">
                    <Button
                        data-testid="create"
                        type="reset"
                        className='general_button'
                        disabled={isButtonDisabled()}
                        onClick={() =>
                            Utils.setDataTypeAndDownloadFile(
                                pdfDimensions,
                                dimensionWarning,
                                downloadOptions,
                                downloadFile)}
                    > {GeneralTexts.generate}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default CreateView
