import { AiOutlineQrcode } from 'react-icons/ai'
import logo from '../../assets/conexo.png'
import { QrRecreatePage } from '../../language/Texts'

function QrRegenerationTitle() {
    return (
        <div className="title-image">
            <h4 className="box_title">
                {QrRecreatePage.regenerateQr}
            </h4>
            <div className="id_image_text">
                <div className="icon_logo">
                    <AiOutlineQrcode className='id_icon' />
                    <img className="id_logo" src={logo} alt="conexo-logo" />
                </div>
                <div className="id_text_part">
                    <h4 className="box_text_title">
                        {QrRecreatePage.regeneratefromQRCodes}
                    </h4>
                    <p className="id_text">
                        {QrRecreatePage.regenerateText}
                    </p>

                </div>
            </div>
        </div>
    )
}

export default QrRegenerationTitle
