import { faAnglesLeft, faAnglesRight, faCheckCircle, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { AiOutlineQrcode } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { SidebarTexts } from '../../language/Texts';
import { Paths } from '../../models/Paths';
import KeycloakService from '../../utils/KeycloakService';
import { Utils } from '../../utils/Utils';
import LanguageSelector from './LanguageSelector';
import './Sidebar.css';

const Sidebar = ({ children, filteredManufacturerList }) => {
    const sidebar_item_style = (show: boolean) => {
        return { justifyContent: isOpen ? "unset" : "center", display: show ? "" : "none" }
    }
    const [windowWidth, setWindowWidth] = useState(0);
    const [isOpen, setIsOpen] = useState(window.innerWidth > 650);
    const token = sessionStorage.getItem('token')
    const toggle = () => setIsOpen(!isOpen);
    let username = Utils.tokenDecoder(token!)
    const userInfos = Utils.getUserInfos(token!)
    const isAdmin = userInfos?.roles?.includes("ilg-admin")
    const sidebar_text_style = { display: isOpen ? "block" : "none" }

    const menuItem = [
        {
            path: Paths.retrofitcreate,
            name: SidebarTexts.createRetrofit,
            icon: <AiOutlineQrcode className="sidebar_icon" />,
            show: true
        },
        {
            path: Paths.idlinkcreate,
            name: SidebarTexts.createIdLink,
            icon: <AiOutlineQrcode className="sidebar_icon" />,
            show: filteredManufacturerList?.length > 0
        },
        {
            path: Paths.trustedmanufacturers,
            name: SidebarTexts.trustedManufacturer,
            icon: <FontAwesomeIcon className="sidebar_icon" icon={faCheckCircle} />,
            show: isAdmin
        },
        {
            path: Paths.regenerate,
            name: SidebarTexts.recreateQrCode,
            icon: <AiOutlineQrcode className="sidebar_icon" />,
            show: true
        }
    ]
    // Change and set sidebar's width according to window's width
    const getSidebarWidth = () => {
        const sidebarWidth = Utils.setSidebarWidth()
        if (isOpen) {
            return sidebarWidth.openWidth
        } else {
            return sidebarWidth.closedWidth
        }
    }

    let resizeWindow = () => {
        getSidebarWidth()
        setIsOpen(window.innerWidth > 650)
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    return (
        <div data-testid="sidebar" className="sidebar_wrapper">
            <div style={{ width: getSidebarWidth() }} className="sidebar">
                <div style={sidebar_item_style(true)} className="sidebar_item sidebar_user" >
                    <FontAwesomeIcon className="sidebar_icon" icon={faUser} />
                    <p className="sidebar_text" style={sidebar_text_style}>{username}</p>
                </div>
                <div style={sidebar_item_style(true)} className="sidebar_item sidebar_logout" onClick={() => KeycloakService.logout()} >
                    <FontAwesomeIcon className="sidebar_icon" icon={faRightFromBracket} />
                    <p className="sidebar_text" style={sidebar_text_style}>{SidebarTexts.logout}</p>
                </div>
                {
                    menuItem.map((item, index) => (
                        <NavLink style={sidebar_item_style(item.show)} to={item.path} key={index} className="sidebar_item link" >
                            <div className="sidebar_icon">{item.icon}</div>
                            <div style={sidebar_text_style} className="sidebar_text">{item.name}</div>
                        </NavLink>
                    ))
                }
                <div className="toggle_button sidebar_item" style={sidebar_item_style(true)} >
                    <div>
                        <LanguageSelector isOpen={isOpen} />
                        <div className="sidebar_footer" onClick={toggle}>
                            <FontAwesomeIcon className="sidebar_icon" icon={isOpen ? faAnglesLeft : faAnglesRight} />
                            <p className="sidebar_text" style={sidebar_text_style}>{SidebarTexts.minimizeMenu}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main_part'>
                {children}
            </div>
        </div >
    )
}

export default Sidebar;