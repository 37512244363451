import axios, { AxiosResponse } from 'axios';
import { IBackendResult } from '../models/IBackendResult';
import { IDownloadOptions } from '../models/IDownloadOptions';
import { IGetManufacturerListResult } from '../models/IGetManufacturerListResult';
import { IIdLinkCreate } from '../models/IIdCreate';
import { IQrRegenerate } from '../models/IQrRegenerate';

export class HttpRequests {
    private token = sessionStorage.getItem('token')
    private axiosInstance = axios.create({
        headers: {
            'Authorization': `Bearer ${this.token}`
        }
    });

    async uploadFile(file: File): Promise<IBackendResult> {
        const data = this.createFormData(file)
        return this.axiosInstance.post('/api/v1/file', data)
            .then(result => result.data)
            .catch(error => {
                console.log('Error on uploading File', error);
                throw error
            })
    }

    async getManufacturerList(): Promise<IGetManufacturerListResult> {
        return this.axiosInstance.get("/api/v1/manufacturers")
            .then(result => result)
            .catch(error => {
                console.log('Error on getting manufacturer list', error);
                throw error
            })
    }

    async getUserRights(id: string): Promise<IBackendResult> {
        return this.axiosInstance.get(`/api/v1/user-rights/${id}`)
            .then(result => result.data)
            .catch(error => {
                console.log('Error on getting manufacturer list', error);
                throw error
            })
    }

    async updateManufacturer(data: FormData): Promise<IBackendResult> {
        return this.axiosInstance.put('/api/v1/manufacturers', data)
            .then(result => result.data)
            .catch(error => {
                console.log('Error on updating manufacturer', error);
                throw error
            })
    }

    async deleteManufacturer(id: string): Promise<IBackendResult> {
        return this.axiosInstance.delete('/api/v1/delete', {
            data: {
                trusted_manufacturer_id: id
            }
        })
            .then(result => result.data)
            .catch(error => {
                console.log('Error on deleting manufacturer', error);
                throw error
            })
    }

    async createIdLinksAndGetFile(data: IIdLinkCreate): Promise<AxiosResponse<File, File>> {
        return this.axiosInstance.post('/api/v1/process-file', data, {
            responseType: 'blob'
        })
            .then(res => res)
            .catch(error => {
                console.log('Error on getting zip file', error);
                throw error
            })
    }

    async qrRegenerate(data: IQrRegenerate): Promise<AxiosResponse<File, File>> {
        return this.axiosInstance.post('/api/v1/qr-regenerate', data, {
            responseType: 'blob'
        })
            .then(res => res)
            .catch(error => {
                console.log('Error on getting zip file', error);
                throw error
            })
    }

    async getFile(data: { manufacturerId: string, fileName: string, downloadOptions: IDownloadOptions }): Promise<AxiosResponse<File, File>> {
        return this.axiosInstance.post('/api/v1/history/file', data, {
            responseType: 'blob',
        })
            .then(res => res)
            .catch(error => {
                console.log('Error on getting zip file', error);
                throw error
            })
    }

    async getHistory(manufacturerIdList: string[]): Promise<IBackendResult> {
        return this.axiosInstance.get('/api/v1/history', {
            params: { manufacturerIdList: manufacturerIdList }
        })
            .then(result => result.data)
            .catch(error => {
                console.log('Error on getting history', error);
                throw error
            })
    }

    private createFormData = (file: File) => {
        if (file === null)
            return

        let formData = new FormData()
        formData.append("file", file)
        return formData
    }
}