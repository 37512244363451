import { GeneralTexts } from '../../language/Texts'
import './ErrorPage.css'

function ErrorPage() {
    return (
        <div className='error_page'>
            <h2>{GeneralTexts.sthWrong}</h2>
        </div>
    )
}

export default ErrorPage
